<template>
  <div>
    <b-row class="m-0 justify-content-center">
      <b-col class="content-header" cols="9">
        <h2 class="content-header-title float-left m-0 pr-1 mr-1">
          {{ $route.meta.pageTitle }}
        </h2>
        <div class="content-header-search mr-1">
          <h3 class="m-0">
            Avoir fournisseur {{ documentData.documentReference }}
          </h3>
        </div>
        <div class="content-header-actions d-flex">
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="24"
            @click="cancel()"
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="content-scrollable-sticky m-0 h-100 justify-content-center">
      <b-col cols="9" class="content-scrollable-sticky-main">
        <validation-observer ref="formDeductCreditProvider">
          <b-form autocomplete="off">
            <div id="general" class="my-0">
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group label="Fournisseur *" label-for="customer">
                    <validation-provider
                      #default="{ errors }"
                      name="Customer"
                      rules="required"
                    >
                      <b-form-input
                        id="amount"
                        v-model="companyName"
                        :state="errors.length > 0 ? false : null"
                        class="not-autocomplete"
                        autocomplete="nope"
                        aria-autocomplete="nope"
                        disabled
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group label="Reste à payer (€)*" label-for="amount">
                    <validation-provider
                      #default="{ errors }"
                      name="amount"
                      rules="required"
                    >
                      <b-form-input
                        id="amount"
                        v-model="amount"
                        :state="errors.length > 0 ? false : null"
                        class="not-autocomplete"
                        type="number"
                        autocomplete="nope"
                        aria-autocomplete="nope"
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <div>
                <b-row class="my-0">
                  <b-col cols="12" class="py-0 mb-1">
                    <b-form-group
                      label="Choix des avoirs fournisseur"
                      label-for="selectDocument"
                    >
                      <validation-provider #default="{ errors }" name="">
                        <v-select
                          multiple
                          id="selectDocument"
                          :loading="isLoadingDocumentsList"
                          :state="errors.length > 0 ? false : null"
                          v-model="documentsSelected"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="gridData"
                          :get-option-label="
                            (option) => option.documentReference
                          "
                          :closeOnSelect="false"
                          @input="autoDistribution"
                        >
                          <template
                            #option="{
                              nature,
                              documentReference,
                              companyName,
                              remainingToPayTtc,
                              totalTTC,
                            }"
                          >
                            {{ documentReference }} - Fournisseur:
                            {{ companyName }} - Reste à solder:
                            {{ formatCurrency(remainingToPayTtc) }} sur
                            {{ formatCurrency(totalTTC) }} TTC
                          </template>
                          <template v-slot:no-options>
                            <template> Aucun résultat trouvé </template>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="my-0 mt-2">
                  <b-col
                    :style="remainsToBeDeducted < 0 ? 'color:red' : ''"
                    class="py-0"
                    cols="12"
                  >
                    <h4>Répartition des avoirs</h4>
                    <p>
                      Reste à répartir :
                      {{ formatCurrency(remainsToBeDeducted) }}
                    </p>
                  </b-col>
                </b-row>
                <b-col class="array-distribution-payment">
                  <b-row class="array-header">
                    <b-col class="array-cell" cols="3">
                      <span>Document</span>
                    </b-col>
                    <b-col class="array-cell" cols="3">
                      <span>Fournisseur</span>
                    </b-col>
                    <b-col class="array-cell" cols="2">
                      <span>Total TTC</span>
                    </b-col>
                    <b-col class="array-cell" cols="2">
                      <span>Reste à solder</span>
                    </b-col>
                    <b-col class="array-cell" cols="2">
                      <span>Montant à déduire</span>
                    </b-col>
                  </b-row>
                  <b-row
                    v-if="documentsSelected.length > 0"
                    class="array-content"
                    v-for="(item, index) in documentsSelected"
                    :key="index"
                  >
                    <b-col class="array-cell" cols="3">
                      <span
                        >Facture fournisseur {{ item.documentReference }}</span
                      >
                    </b-col>
                    <b-col class="array-cell" cols="3">
                      <span>{{ item.provider.name }}</span
                      ><br />
                    </b-col>
                    <b-col class="array-cell" cols="2">
                      <span>{{ formatCurrency(item.totalTTC) }}</span>
                    </b-col>
                    <b-col class="array-cell" cols="2">
                      <span>{{ formatCurrency(item.remainingToPayTtc) }}</span>
                    </b-col>
                    <b-col class="array-cell" cols="2">
                      <validation-provider name="creditProviderDistribution">
                        <input
                          @blur="
                            deductRemainsToBeDeducted(
                              index,
                              item.creditProviderDistribution
                            )
                          "
                          type="number"
                          class="mb-0"
                          id="creditsProviderDistribution"
                          v-model="item.creditProviderDistribution"
                        />
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row
                    v-if="documentsSelected.length == 0"
                    class="p-1 justify-content-center d-flex"
                  >
                    <span>Aucun avoir séléctionné pour la répartition</span>
                  </b-row>
                </b-col>
              </div>
            </div>
          </b-form>
        </validation-observer>
        <b-row class="my-0">
          <b-col cols="12" class="py-0">
            <div
              class="d-flex justify-content-between mt-2"
              style="border-top: 1px solid #e2e2e2; padding-top: 0.5rem"
            >
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  @click="cancel()"
                >
                  <feather-icon icon="ArrowLeftIcon" />
                  Annuler
                </b-button>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="create"
              >
                <feather-icon icon="PlusIcon" />
                {{ "Ajouter" }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Autocomplete from "vuejs-auto-complete";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required } from "@validations";

configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      required: "Ce champ est requis",
    },
  }),
});
localize("fr");

import { mapGetters, mapActions } from "vuex";
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  props: {
    documentData: {
      default: null,
    },
  },
  data() {
    return {
      required,
      companyName: null,
      amount: 0,
      documentsSelected: [],
      remainsToBeDeducted: 0,
      initialRemainsToBeDeducted: 0,
      documentListForDeduction: [],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  created() {
    this.popupPaymentEvent();
  },
  methods: {
    ...mapActions([
      "getCreditsProvidersByProviderIds",
      "updateInvoiceProviderCreditProvider",
    ]),
    formatCurrency,
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(
        ".content-scrollable-sticky-nav ul li a"
      );
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach((link) => {
        let section = document.querySelector(link.hash);
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    popupPaymentEvent(id) {
      if (this.documentData != null) {
        this.initializeFormWithDocumentData();
        this.fetchDocuments();
      }
    },
    initializeFormWithDocumentData() {
      let documentData = structuredClone(this.documentData);
      this.documentsSelected = [];
      this.companyName = documentData.provider.name;
      this.amount =
        this.initialRemainsToBeDeducted =
        this.remainsToBeDeducted =
          this.documentData.remainingToPayTtc;
    },
    fetchDocuments() {
      this.documentsSelected = [];
      this.getCreditsProvidersByProviderIds({
        providerIds: [this.documentData.companyId],
        status: [2, 3],
      }).then((res) => {
        this.documentListForDeduction = res;
      });
    },
    autoDistribution() {
      let alreadyDeducted = 0;
      for (let i = 0; i < this.documentsSelected.length; i++) {
        const document = this.documentsSelected[i];
        let calc =
          Math.round(
            (this.initialRemainsToBeDeducted -
              document.remainingToPayTtc -
              alreadyDeducted) *
              100
          ) / 100;
        if (calc >= 0) {
          this.documentsSelected[i].creditProviderDistribution =
            this.documentsSelected[i].remainingToPayTtc;
          this.remainsToBeDeducted =
            Math.round(
              (this.initialRemainsToBeDeducted - document.remainingToPayTtc) *
                100
            ) / 100;
          alreadyDeducted +=
            Math.round(
              this.documentsSelected[i].creditProviderDistribution * 100
            ) / 100;
        } else {
          this.remainsToBeDeducted = 0;
          this.documentsSelected[i].creditProviderDistribution =
            Math.round(
              (this.initialRemainsToBeDeducted - alreadyDeducted) * 100
            ) / 100;
          alreadyDeducted +=
            Math.round(
              this.documentsSelected[i].creditProviderDistribution * 100
            ) / 100;
        }
      }
    },
    create(e) {
      e.preventDefault();
      this.$refs.formDeductCreditProvider
        .validate()
        .then((success1) => {
          if (success1 && this.remainsToBeDeducted >= 0) {
            let creditProviders = Object.fromEntries(
              this.documentsSelected.map((item) => [
                item.id,
                item.creditProviderDistribution,
              ])
            );
            this.updateInvoiceProviderCreditProvider({
              invoiceProviderId: this.documentData.id,
              creditProviders: creditProviders,
            }).then(() => {
              this.$nextTick(() => {
                if (
                  this.$route.params.routeOrigine &&
                  this.$route.params.routeOrigine !== ""
                ) {
                  this.$tabs.close();
                } else {
                  this.$tabs.close({ to: "/invoices-provider" });
                }
              });
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    cancel() {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title:
            "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$tabs.close();
          }
        });
    },
    async deductRemainsToBeDeducted(index, value) {
      let roundValue = Math.round(value * 100) / 100;
      if (roundValue < 0) {
        this.documentsSelected[index].creditProviderDistribution = 0;
      } else if (roundValue > this.documentsSelected[index].remainingToPayTtc) {
        this.documentsSelected[index].creditProviderDistribution =
          Math.round(this.documentsSelected[index].remainingToPayTtc * 100) /
          100;
      }
      await this.calculateRemainsToBeDeducted();
    },
    calculateRemainsToBeDeducted() {
      this.remainsToBeDeducted =
        this.initialRemainsToBeDeducted -
        this.documentsSelected.reduce((total, document) => {
          return (total +=
            Math.round(document.creditProviderDistribution * 100) / 100);
        }, 0);
    },
  },
  computed: {
    ...mapGetters([
      "natureList",
      "isLoadingDocumentsList",
      "workspaceSelected",
    ]),
    gridData() {
      return this.documentListForDeduction;
    },
  },
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    Autocomplete,
  },
  directives: {
    Ripple,
  },
};
</script>
<style lang="scss">
.array-distribution-payment {
  border-radius: 3px;
  border: solid 1px #d8d6de;

  .array-header {
    border-bottom: solid 1px #d8d6de;
    margin: -12px;
  }

  .array-content:not(:last-child) {
    border-bottom: solid 1px #d8d6de;
  }

  .row .array-cell:not(:last-child) {
    border-right: solid 1px #d8d6de;
    box-sizing: border-box;
  }
}

.inline-spacing {
  margin-top: 0.2857rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.form-control:disabled {
  border: 1px solid #d8d6de;
  background-color: #efefef;
}

.content-scrollable-sticky {
  // display: grid;
  // grid-template-columns: min-content 1fr;
  position: relative;

  .content-scrollable-sticky-nav {
    white-space: nowrap;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li a {
        display: block;
        padding: 1rem 0rem;
        color: white;
        text-decoration: none;
      }

      li a.current {
        background: black;
      }
    }
  }
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  .content-scrollable-sticky-nav ul {
    position: sticky;
    top: 0;
  }
}
</style>
